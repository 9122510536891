import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4739a0ab"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "addon-view-full-text" }
const _hoisted_2 = { class: "is-sr-only" }
const _hoisted_3 = {
  key: 0,
  class: "addon-view-full-text-body addon-view-full-text-no-result level-center is-mobile"
}
const _hoisted_4 = {
  id: "fulltext-not-exist-message",
  class: "has-text-modest"
}
const _hoisted_5 = {
  key: 1,
  class: "addon-view-full-text-base"
}
const _hoisted_6 = { class: "addon-view-full-text-input" }
const _hoisted_7 = { class: "addon-view-full-text-input-inner level-right is-mobile" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "addon-view-fullText" }
const _hoisted_10 = { class: "addon-view-fullText-interval" }
const _hoisted_11 = { class: "addon-view-full-text-body" }
const _hoisted_12 = {
  key: 0,
  class: "addon-view-full-text-result-loading-icon level-center is-mobile"
}
const _hoisted_13 = {
  key: 0,
  class: "addon-view-full-text-result"
}
const _hoisted_14 = {
  key: 1,
  class: "addon-view-full-text-no-result level-center is-mobile"
}
const _hoisted_15 = {
  id: "fulltext-not-found-message",
  class: "has-text-modest"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonIcon = _resolveComponent("ButtonIcon")!
  const _component_KeywordSearch = _resolveComponent("KeywordSearch")!
  const _component_AppInput = _resolveComponent("AppInput")!
  const _component_TextWithLeadingIcon = _resolveComponent("TextWithLeadingIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_AppCheckbox = _resolveComponent("AppCheckbox")!
  const _component_IconLoading = _resolveComponent("IconLoading")!
  const _component_AddonViewFullTextList = _resolveComponent("AddonViewFullTextList")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('rightPane.fullText')), 1),
    (!_ctx.hasFullTextData)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('rightPane.fullTextNotExist', { newLine: '\r\n' })), 1)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_KeywordSearch, {
              modelValue: _ctx.keyword,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.keyword) = $event)),
              placeholder: _ctx.$t('label.keyword'),
              onSearch: _ctx.snippetSearch,
              role: "search",
              label: _ctx.$t('label.keyword')
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, [
                  (_ctx.snippetTotalHit)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.snippetTotalHit) + " " + _toDisplayString(_ctx.$t('label.case')), 1))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ButtonIcon, {
                    styled: "is-none-text",
                    type: "submit",
                    "aria-label": _ctx.$t('parts.search'),
                    class: "addon-view-full-text-search-button",
                    text: _ctx.$t('parts.search')
                  }, null, 8, ["aria-label", "text"])
                ])
              ]),
              _: 1
            }, 8, ["modelValue", "placeholder", "onSearch", "label"]),
            _createElementVNode("form", {
              onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.snippetSearch && _ctx.snippetSearch(...args)), ["prevent"])),
              role: "search"
            }, [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_AppCheckbox, {
                  id: "addon-view-fullTextInterval",
                  modelValue: _ctx.fullTextInterval,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.fullTextInterval) = $event)),
                  "aria-label": `${_ctx.$t('label.fulltextInterval1')} ${_ctx.ftInterval} ${_ctx.$t('label.fulltextInterval2')}`
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t('form.fulltextDetailIntervalPrefix')), 1),
                      _createElementVNode("div", null, [
                        _createVNode(_component_AppInput, {
                          modelValue: _ctx.ftInterval,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.ftInterval) = $event)),
                          "input-id": "addon-view-input-ftInterval",
                          disabled: !_ctx.fullTextInterval,
                          min: 1,
                          max: 1000,
                          type: "number",
                          class: "input-ftInterval",
                          "aria-label": `${_ctx.$t('label.fulltextInterval1')} ${_ctx.ftInterval} ${_ctx.$t('label.fulltextInterval2')}`
                        }, null, 8, ["modelValue", "disabled", "aria-label"])
                      ]),
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t('form.fulltextIntervalSuffix')), 1),
                      _createElementVNode("div", null, [
                        _createVNode(_component_router_link, {
                          to: '/help#idx2-2',
                          id: "to-detail-search-help-fullText-interval"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_TextWithLeadingIcon, {
                              icon: "circle-questionmark",
                              "aria-label": _ctx.$t('label.helpPageLink')
                            }, null, 8, ["aria-label"])
                          ]),
                          _: 1
                        })
                      ])
                    ])
                  ]),
                  _: 1
                }, 8, ["modelValue", "aria-label"])
              ])
            ], 32)
          ]),
          _createElementVNode("div", _hoisted_11, [
            (_ctx.isProcessing)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createVNode(_component_IconLoading)
                ]))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  (_ctx.results.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.results, (content) => {
                          return (_openBlock(), _createBlock(_component_AddonViewFullTextList, {
                            key: content.id,
                            content: content,
                            current: content.id === Number(_ctx.$route.params.contentIndex || 1),
                            selected: _ctx.selectedSnippetId === content.snippetId,
                            onOnClickSnippet: _ctx.selectSnippet
                          }, null, 8, ["content", "current", "selected", "onOnClickSnippet"]))
                        }), 128))
                      ]))
                    : (_ctx.$route.query.keyword)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                          _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.$t('rightPane.fullTextNotFound', { newLine: '\r\n' })), 1)
                        ]))
                      : _createCommentVNode("", true)
                ], 64))
          ])
        ]))
  ]))
}